"use client";
import { H4 } from "movestic-ui/tailwind/Texts";
import { useEffect, useState } from "react";

interface ContentNavListProps {
    content: any;
    selectors: string;
}

export const ContentNavList: React.FC<ContentNavListProps> = ({ content, selectors }) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [headings, setHeadings] = useState<any[]>([]);
    const [activeHeadingIndex, setActiveHeadingIndex] = useState<number>(0);
    const [isNavigating, setIsNavigating] = useState<boolean>(false);

    const handleNavigation = (value: boolean) => {
        setIsNavigating(value);
    };

    useEffect(() => {
        const relevantHeadings: string[] = [];
        const allHeadingElements = document.querySelectorAll(selectors);
        const headingsArray = [];
        const body = content.body;
        // adding the title of the hero as a relevant heading
        if (content && content.title) {
            relevantHeadings.push(content.title);
        }
        // adding all the headings from the rich content body as relevant headings
        if (body && body.content) {
            body.content.forEach((item) => {
                if (item.content && item.type === "heading" && item.content?.length) {
                    relevantHeadings.push(item.content[0]?.text);
                } else if (item.type === "blok") {
                    item.attrs.body.forEach((element) => {
                        if (element.component === "textBlock") {
                            // if the user has added a title via the popout to the text block it should be included as a relevant heading
                            if (element.title) {
                                relevantHeadings.push(element.title);
                            } else {
                                // when the user has added headings inside the rich content without using the popout title field
                                element.text?.content?.forEach((textBlockItem) => {
                                    if (textBlockItem.type === "heading" && textBlockItem.content?.length) {
                                        relevantHeadings.push(textBlockItem.content[0]?.text);
                                    }
                                });
                            }
                        } else if (element.component === "faqList") {
                            // if there are a faqList then take the heading and add it as a relevant heading
                            relevantHeadings.push(element.heading);
                        }
                    });
                }
            });
        }
        allHeadingElements.forEach((headingElement) => {
            if (relevantHeadings.includes(headingElement.textContent)) {
                const rect = headingElement.getBoundingClientRect();
                headingsArray.push({
                    text: headingElement.textContent.replace(/:$/g, ""),
                    y: rect.top + window.scrollY - 75, // get the y positions of the headings relative to the whole document, not only in the viewport
                });
            }
        });
        // adding the y position of the next heading to know where the current heading ends
        headingsArray.forEach((heading, index) => {
            const nextHeading = headingsArray[index + 1];
            heading.nextY = nextHeading ? nextHeading.y : Infinity;
        });
        setHeadings(headingsArray);
    }, []);

    useEffect(() => {
        const handleWheel = (event) => {
            // only run this when the user is not scrolling using the navigation
            if (!isNavigating) {
                const scrollY = window.scrollY + 175; // needed to increase the value because its still 0 after the first scroll, plus the height of the menu
                const innerHeight = window.innerHeight;
                const scrolledToTop = window.scrollY <= 0 && event.deltaY < 0;
                const scrolledToBottom =
                    innerHeight + scrollY >= document.documentElement.scrollHeight && event.deltaY > 0; // the event.deltaY > 0 is necessary to determine the scroll direction
                if (scrolledToTop) {
                    if (event.deltaY < 0) {
                        // if scrolled to top and user continues to scroll up, set the previous heading as active
                        if (activeHeadingIndex > 0) {
                            setActiveHeadingIndex(activeHeadingIndex - 1);
                        } else {
                            // handling the first heading to set that as active
                            setActiveHeadingIndex(0);
                        }
                    } else if (event.deltaY > 0) {
                        // if scrolled to top and user is trying to scroll down, set the active heading to the one below the current scroll position
                        setActiveHeadingIndex(activeHeadingIndex + 1);
                    }
                    // if the user is at the bottom of the page we need to add or substract manually to update the activeHeadinIndex
                } else if (scrolledToBottom) {
                    if (event.deltaY > 0) {
                        // if scrolled to bottom and user continues to scroll down, set the next heading as active
                        if (activeHeadingIndex < headings.length - 1) {
                            setActiveHeadingIndex(activeHeadingIndex + 1);
                        } else {
                            // handling the last heading to set that as active
                            setActiveHeadingIndex(headings.length - 1);
                        }
                    } else if (event.deltaY < 0) {
                        // if scrolled to bottom and user is trying to scroll up, set the active heading to the one above the current scroll position
                        setActiveHeadingIndex(activeHeadingIndex - 1);
                    }
                } else {
                    // setting the active heading based on which heading that are in the viewport based on the current and next heading
                    let newActiveHeadingIndex = headings.findIndex(
                        (heading) => scrollY >= heading.y && scrollY <= heading.nextY,
                    );
                    // if no headings are in the viewport, set the active heading to the last one
                    if (newActiveHeadingIndex === -1 && activeHeadingIndex !== -1) {
                        newActiveHeadingIndex = activeHeadingIndex;
                    }

                    setActiveHeadingIndex(newActiveHeadingIndex);
                }
            }
        };
        // using "wheel" instead of "scroll" to trigger event even though the end of the page is reached
        window.addEventListener("wheel", handleWheel);
        return () => {
            window.removeEventListener("wheel", handleWheel);
        };
    }, [isNavigating, headings, activeHeadingIndex]);
    useEffect(() => {
        if (activeHeadingIndex !== null) {
            setActiveIndex(activeHeadingIndex);
        }
    }, [activeHeadingIndex]);

    return (
        <div className="sticky top-24">
            <H4>Hitta på sidan</H4>
            {headings?.map((heading, index) => (
                <ContentNavItem
                    key={index}
                    setActiveIndex={setActiveIndex}
                    index={index}
                    heading={heading.text}
                    headings={headings}
                    active={activeIndex === index}
                    handleNavigation={handleNavigation}
                />
            ))}
        </div>
    );
};

interface ContentNavItemProps {
    heading: string;
    headings: any[];
    index: number;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    active: boolean;
    handleNavigation?: (value: boolean) => void;
}

const ContentNavItem: React.FC<ContentNavItemProps> = ({
    heading,
    headings,
    setActiveIndex,
    index,
    active,
    handleNavigation,
}) => {
    const clickNavigateToHeading = (selectedHeading?: string) => {
        handleNavigation(true);
        setActiveIndex(index);
        const relevantHeading = headings.find((headingItem) => headingItem.text === selectedHeading);
        const top = relevantHeading.y; //(index === 0 ? 75 : 0);
        window.scroll({ top, behavior: "smooth" });

        // setting isNavigating to false when the auto scrolling is done so it wont interfere with the manual scrolling
        setTimeout(() => {
            handleNavigation(false);
        }, 1000);
    };
    return (
        <div
            className={`py-3 px-6 border-l-4 cursor-pointer hover:underline ${active ? "border-cherryRed bg-cherryMist" : "border-lightGray bg-transparent"}`}
            onClick={() => clickNavigateToHeading(heading)}
        >
            <div>{heading}</div>
        </div>
    );
};
